<template>
    <div class="wain sc" id="show">
        <!-- 头部-->
        <header class="hcc">
            <img src="@/assets/img/leftarrow.png" alt="" class="leftarrow" @click="backArrow">
            <p>修改登录密码</p>
        </header>
        <!-- 主体 -->
        <section class="sc">
            <!-- 密码框 -->
            <div class="posscase sc">
                <input type="password" class="setpossword" placeholder="请输入旧密码" v-model="oldpassword">
                <input type="password" class="setpossword" placeholder="请输入新密码(不少于6位)" v-model="newpassword">
                <input type="password" placeholder="请再次输入密码(不少于6位)" v-model="againpassword">

            </div>
            <!-- 修改按钮 -->
            <button @click="confirm">确认修改</button>
        </section>
    </div>

</template>

<script>
import watermark from '@/watermark/watermark';

export default {
    name: 'modify_possword',

    components: {

    },
    data() {
        return {
            oldpassword: "",
            newpassword: "",
            againpassword: ""
        }
    },
    mounted() {
        this.wxfx();
        //判断有没有水印
        if (localStorage.getItem("shuiyin_status") == 1) {
            watermark.set(
                localStorage.getItem("logo")
            )
        } else {
            watermark.out(
                localStorage.getItem("logo")
            )
        }


    },

    methods: {
        // 点击返回上一级
        backArrow() {
            this.$router.back()
        },
        // 点击确认按钮调取修改接口
        confirm() {
            // 如果两次密码一致
            if (this.newpassword == this.againpassword && this.newpassword && this.againpassword && this.oldpassword) {
                this.$axios({
                    url: "http://y163.njhxzx.com/data/user/updatePassword",
                    methods: "post",
                    params: {
                        token: localStorage.getItem("token"),
                        password: this.newpassword,
                        passwordOld: this.oldpassword
                    }
                }).then(res => {
                    this.$toast.success('修改成功')
                    setTimeout(() => {
                        // 如果获取成功 跳转到登录
                        this.$router.push({
                            path: "/login"
                        })
                    }, 2000)


                }).catch(err => {

                })
            } else {
                this.$toast.fail('输入密码不能为空并保证两次输入密码一致')
            }



        }
    },
}
</script>



<style lang="scss" scoped>
#show {
    position: relative;
    z-index: 999;
    overflow: hidden;
}

.wain {
    width: 100%;
    height: 100%;
    background-color: #F0F3F8;



    // 头部
    header {
        width: 750rem;
        height: 110rem;
        background-color: #FA784F;
        position: relative;

        img {
            width: 21rem;
            height: 38rem;
            margin-left: 20rem;
        }

        p {
            font-size: 38rem;
            color: #fff;
        }

        .leftarrow {
            position: absolute;
            left: 20rem;

        }
    }

    // 主体
    section {
        width: 100%;
        height: 100vh;

        .posscase {
            width: 100%;
            height: 330rem;
            background-color: #fff;
            margin-top: 40rem;

            input {
                width: 95%;
                height: 110rem;
                font-size: 35rem;
                color: #999;

            }

            .setpossword {
                border-bottom: 1rem solid #0000001d;
            }
        }

        // 确认修改按钮
        button {
            width: 700rem;
            height: 100rem;
            border-radius: 100rem;
            background-color: #FA784F;
            font-size: 40rem;
            color: #fff;
            margin-top: 170rem;
        }

        .tipcase {
            width: 100%;
            height: 200rem;
            // background-color: #849c17;

            p {
                font-size: 30rem;
                color: #FA784F;
            }
        }

    }
}
</style>
