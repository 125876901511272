<template>
    <div class="wain sc" id="show">
        <!-- 头部 -->
        <header>
            <!-- 搜索模块 -->
            <div class="searchcase hca">
                <img src="@/assets/img/search.png" alt="" srcset="">
                <input type="text" placeholder="请输入医院或医生名称" v-model="search" @keyup="colsekey" @keydown.13="ent">
                <img src="@/assets/img/colse.png" alt="" srcset="" class="colseone" @click="colsesone"
                    v-show="colseone">
                <button @click="getSearch">搜索</button>
            </div>
        </header>

        <!-- 主体部分 -->
        <section class="sc">
            <!-- 历史记录栏 -->
            <div class="historycase" v-show="historycase">

                <!-- 上方标题盒子 -->
                <div class="historytitlecase hcb">
                    <!-- 标题 -->
                    <div class="historytitle hca">
                        <img src="@/assets/img/line.png" alt="" class="line">
                        <p>历史记录</p>
                    </div>

                    <!-- 删除图标 -->
                    <img src="@/assets/img/shape.png" alt="" class="shape" @click="removeDelete">
                </div>

                <!-- 下方内容盒子 -->
                <div class="historycontent">
                    <!-- 历史列表 -->
                    <ul class="historylist">
                        <li v-for="r in historyList" @click="getHistorySearch(r)">{{ r }}</li>
                        <!-- 空盒子 -->
                        <div></div>
                        <div></div>
                        <div></div>
                    </ul>
                </div>
            </div>

            <!-- 热门搜索栏 -->
            <div class="hotcase" v-show="hotcase">
                <!-- 上方标题盒子 -->
                <div class="hottitlecase hcb">
                    <!-- 标题 -->
                    <div class="hottitle hca">
                        <img src="@/assets/img/line.png" alt="" class="line">
                        <p>热门搜索</p>
                    </div>
                </div>

                <!-- 下方热门搜索内容盒子 -->
                <div class="hotcontent">
                    <!-- 热门搜索列表 -->
                    <ul class="hotlist">
                        <li v-for="r in hotlist" :key="r.id" @click="getHotlists(r.code)">{{ r.code }}</li>
                        <!-- 空盒子 -->
                        <div></div>
                        <div></div>
                        <div></div>
                    </ul>
                </div>

            </div>

            <!-- 搜索结果 -->
            <div class="searchresult" v-show="searchresult">
                <!-- 上方标题盒子 -->
                <div class="searchtitlecase hcb">
                    <!-- 标题 -->
                    <div class="searchtitle hca">
                        <img src="@/assets/img/line.png" alt="" class="line">
                        <p>搜索结果</p>
                    </div>
                </div>
                <img src="@/assets/img/colse.png" alt="" class="colse" @click="colsestwo" v-show="colsetwo">
                <!--  -->
                <div class="wordone hcc" v-show="searchword">
                    <p>没有找到相关内容</p>

                </div>
                <!-- 下方列表 -->
                <div class="searchlistCase ">
                    <div class="searchlist" v-for="r in list" :key="r.id" @click="searchlist(r.code)">
                        <p>{{ r.name }}</p>
                        <div class="searchword hca">
                            <p>{{ r.username }}</p>
                            <span>{{ r.create_at.slice(0, 12) }}</span>
                        </div>
                    </div>
                    <div class="search hca" v-show="footFlag">
                        <img src="@/assets/img/horizontal_line.png" alt="" srcset="">
                        <p class="foot">到底了</p>
                        <img src="@/assets/img/horizontal_line.png" alt="" srcset="">
                    </div>

                </div>
            </div>
        </section>
        <my-footer></my-footer>

    </div>

</template>

<script>
//引用文件
import myFooter from '@/components/myFooter.vue'
import watermark from '@/watermark/watermark';

export default {
    name: 'index',

    components: {
        myFooter
    },
    data() {
        return {
            search: "",
            historyList: [],
            hotlist: "",
            hotcase: true,
            historycase: true,
            searchresult: false,
            list: "",
            edittitle: "",
            searchword: false,
            colseone: false,
            colsetwo: false,
            footFlag: ""
        }
    },

    mounted() {
        this.wxfx();
        //判断有没有水印
        if (localStorage.getItem("shuiyin_status") == 1) {
            watermark.set(
                localStorage.getItem("logo")
            )
        } else {
            watermark.out(
                localStorage.getItem("logo")
            )
        }

        // 加载页面时把缓存中的内容渲染到页面中
        if (JSON.parse(localStorage.getItem("historyList"))) {
            this.historyList = JSON.parse(localStorage.getItem("historyList"));
        }
        // 当大于的6条记录时删除最后一条记录
        if (this.historyList.length >= 6) {
            this.historyList.pop()
        }
        //渲染热门搜索内容
        this.$axios({
            url: "http://y163.njhxzx.com/data/article/getSearch",
            methods: "get",
            params: {
                token: localStorage.getItem("token"),
            }
        }).then(res => {
            // 如果获取成功
            if (res.data.code == 1) {
                this.hotlist = res.data.data.list
            }

        }).catch(err => {

        })


    },

    methods: {
        // 操作输入框时显示关闭图片
        colsekey() {
            if (this.search) {
                this.colseone = true
            } else {
                this.colseone = false
            }

        },
        // 回车搜索
        ent() {
            if (this.search != "") {
                this.historyList.unshift(this.search)
                localStorage.setItem("historyList", JSON.stringify(this.historyList));

            }
            if (this.historyList.length >= 6) {
                this.historyList.pop()
            }
            this.searchword = false
            this.footFlag = true

            this.hotcase = false,
                this.historycase = false,
                this.searchresult = true
            this.colseone = false,
                this.colsetwo = true
            // 调取文章列表接口
            this.$axios({
                url: "http://y163.njhxzx.com/data/article/index",
                methods: "get",
                params: {
                    token: localStorage.getItem("token"),
                    keyword: this.search
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.list = res.data.data.list
                }
                if (this.list.length == 0) {
                    this.searchword = true
                    this.footFlag = false
                }

            }).catch(err => {

            })
        },
        // 点击搜索时把输入框里的内容已数组的方式存到缓存中
        getSearch() {
            if (this.search != "") {
                this.historyList.unshift(this.search)
                localStorage.setItem("historyList", JSON.stringify(this.historyList));

            }
            if (this.historyList.length >= 6) {
                this.historyList.pop()
            }
            this.searchword = false
            this.footFlag = true

            this.hotcase = false,
                this.historycase = false,
                this.searchresult = true
            this.colseone = false,
                this.colsetwo = true
            // 调取文章列表接口
            this.$axios({
                url: "http://y163.njhxzx.com/data/article/index",
                methods: "get",
                params: {
                    token: localStorage.getItem("token"),
                    keyword: this.search
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.list = res.data.data.list
                }

                if (this.list.length == 0) {
                    this.searchword = true
                    this.footFlag = false
                }

            }).catch(err => {

            })


        },
        // 点击首页关闭按钮
        colsesone() {
            this.search = ""
            this.colseone = false
        },
        // 点击搜索结果列表关闭按钮
        colsestwo() {
            this.searchresult = false
            this.hotcase = true,
            this.historycase = true
            this.$router.go(0)
            this.searchword = false
            this.search = ""




        },
        // 点击删除时清空缓存并刷新页面
        removeDelete() {
            localStorage.removeItem("historyList")
            this.$router.go(0)

        },
        // 点击历史记录时获取当前的值 并调取接口
        getHistorySearch(e) {
            this.search = e

            // 调取文章列表接口
            this.$axios({
                url: "http://y163.njhxzx.com/data/article/index",
                methods: "get",
                params: {
                    token: localStorage.getItem("token"),
                    keyword: e
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.list = res.data.data.list
                    this.footFlag = true
                }
                if (this.list.length == 0) {
                    this.searchword = true
                    this.footFlag = false
                }

            }).catch(err => {

            })
            this.hotcase = false,
                this.historycase = false,
                this.searchresult = true
            this.colseone = false,
                this.colsetwo = true
        },

        // 点击热门搜索获取当前的值 并调取接口
        getHotlists(e) {
            this.search = e
            this.historyList.unshift(e)
            localStorage.setItem("historyList", JSON.stringify(this.historyList));
            if (this.historyList.length >= 6) {
                this.historyList.pop()
            }
            // 调取文章列表接口
            this.$axios({
                url: "http://y163.njhxzx.com/data/article/index",
                methods: "get",
                params: {
                    token: localStorage.getItem("token"),
                    keyword: e
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.list = res.data.data.list
                    this.footFlag = true
                }
                if (this.list.length == 0) {
                    this.searchword = true
                    this.footFlag = false
                }

            }).catch(err => {

            })
            this.hotcase = false,
                this.historycase = false,
                this.searchresult = true
            this.colseone = false,
                this.colsetwo = true
        },
        searchlist(e) {
            localStorage.setItem("path", "/index")
            this.$router.push({
                path: "/click_details",
                query: {
                    codeid: e,


                }
            })
        }
    },
}
</script>



<style lang="scss" scoped>
.element::-webkit-scrollbar {
    width: 0 !important
}

#show {
    position: relative;
    z-index: 999;
    overflow: hidden;
}

// 外边大盒子
.wain {
    width: 100%;
    height: 100vh;



    .disabled {
        pointer-events: none;
        cursor: default;
        // opacity: 0.6;
    }

    // 头部
    header {
        width: 704rem;
        background-color: #fff;

        // 搜索模块
        .searchcase {
            width: 100%;
            height: 140rem;
            position: relative;
            margin-top: 20rem;

            img {
                width: 36rem;
                height: 36rem;
                position: absolute;
                left: 18rem;
                top: 54rem;
            }

            input {
                width: 600rem;
                height: 70rem;
                border-radius: 100rem;
                padding-left: 60rem;
                background: #F6F6F6;
                font-weight: 500;
                color: #656565;
                font-size: 22rem;

            }

            button {
                width: 70rem;
                height: 50rem;
                background-color: #fff;
                color: #000;
                font-weight: 600;
                font-size: 34rem;
                margin-left: 20rem;
            }

            .colseone {
                left: 540rem;

            }

        }
    }

    // 主体
    section {
        width: 100%;

        // 历史记录模块
        .historycase {
            width: 690rem;
            height: 205rem;

            // 历史记录标题盒子
            .historytitlecase {
                width: 100%;
                height: 45rem;


                // 标题
                .historytitle {
                    width: 132rem;
                    height: 45rem;

                    .line {
                        height: 23rem;
                    }

                    p {
                        font-size: 28rem;
                    }

                }

                .shape {
                    width: 37rem;
                    height: 40rem;

                }
            }

            // 下方内容盒子
            .historycontent {
                width: 690rem;
                height: 160rem;

                // 历史列表
                .historylist {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    width: 100%;

                    li {
                        height: 50rem;
                        background-color: #F6F6F6;
                        color: #656565;
                        padding: 28rem;
                        border-radius: 100rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 26rem;
                        font-size: 22rem;
                        margin-left: 20rem;

                    }

                    li:hover {
                        color: #FF784F;
                    }

                    // 空盒子
                    div {
                        width: 133rem;
                        height: 50rem;
                    }

                }

            }
        }

        // 热门搜索模块
        .hotcase {
            width: 690rem;
            height: 205rem;
            margin-top: 100rem;

            // 热门搜索标题盒子
            .hottitlecase {
                width: 100%;
                height: 45rem;


                // 标题
                .hottitle {
                    width: 132rem;
                    height: 45rem;

                    .line {
                        height: 23rem;
                    }

                    p {
                        font-size: 28rem;
                    }

                }
            }

            // 下方内容盒子
            .hotcontent {
                width: 690rem;

                // 热门搜索列表
                .hotlist {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;

                    li {
                        height: 50rem;
                        background-color: #F6F6F6;
                        color: #656565;
                        padding: 28rem;
                        border-radius: 100rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 26rem;
                        font-size: 22rem;
                        margin-left: 20rem;
                    }

                    li:hover {
                        color: #FF784F;
                    }

                    // 空盒子
                    div {
                        width: 133rem;
                        height: 50rem;
                    }

                }

            }
        }

        // 搜索结果
        .searchresult {
            width: 690rem;
            height: 205rem;
            // background-color: #FF784F;

            .searchtitlecase {
                width: 100%;
                height: 45rem;

                // 标题
                .searchtitle {
                    width: 132rem;
                    height: 45rem;

                    .line {
                        height: 23rem;
                    }

                    p {
                        font-size: 28rem;
                    }

                }
            }

            .colse {
                width: 36rem;
                height: 36rem;
                position: absolute;
                right: 151rem;
                top: 74rem;
            }

            .searchlistCase {
                width: 100%;
                height: 960rem;
                overflow: hidden;
                overflow-y: scroll;

                .search {
                    width: 465rem;
                    height: 40rem;
                    margin-left: 97rem;
                    margin-top: 30rem;


                    .foot {
                        font-size: 30rem;
                        color: #999;
                    }
                }


                .searchlist {
                    width: 100%;
                    height: 170rem;
                    border-bottom: 1rem solid #0000000d;
                    margin-top: 30rem;
                    padding-left: 10rem;


                    p {
                        font-size: 36rem;
                    }

                    .searchword {
                        width: 420rem;
                        height: 50rem;
                        align-items: flex-end;
                        margin-top: 30rem;
                        margin-left: -21rem;

                        p {
                            font-size: 33rem;
                            color: #999;
                        }

                        span {
                            font-size: 21rem;
                            color: #999;
                            margin-left: 30rem;
                        }
                    }
                }


            }



            .wordone {
                width: 700rem;
                height: 80rem;

                // background-color: #FF784F;
                p {
                    font-size: 25rem;
                    color: #999;
                }
            }
        }
    }
}
</style>