import Vue from 'vue'
import VueRouter from 'vue-router'
const index = () => import( /* webpackChunkName:"index" */ '../views/index.vue');
const mine = () => import( /* webpackChunkName:"mine" */ '../views/mine.vue')
const my_collection = () => import( /* webpackChunkName:"my_collection" */ '../views/my_collection.vue')
const favorite_details = () => import( /* webpackChunkName:"favorite_details" */ '../views/favorite_details.vue')
const create_avorites = () => import( /* webpackChunkName:"create_avorites" */ '../views/create_avorites.vue')
const browse_records = () => import( /* webpackChunkName:"browse_records" */ '../views/browse_records.vue')
const click_details = () => import( /* webpackChunkName:"click_details" */ '../views/click_details.vue')
const set_possword = () => import( /* webpackChunkName:"set_possword" */ '../views/set_possword.vue')
const modify_possword = () => import( /* webpackChunkName:"modify_possword" */ '../views/modify_possword.vue')
const login_cover = () => import( /* webpackChunkName:"login_cover" */ '../views/login_cover.vue')
const login = () => import( /* webpackChunkName:"login" */ '../views/login.vue')



Vue.use(VueRouter)
const routes = [{
    path: '/',
    redirect: "/login_cover"
  },
  {
    path: '/index',
    name: '首页',
    component: index,
    icon: "iconfont icon-shouye-shouye icon"
  },
  {
    path: '/mine',
    name: '我的',
    component: mine,
    icon: "iconfont icon-wode-wode icon"
  },
  {
    path: '/my_collection',
    name: '我的收藏',
    component: my_collection,
  },
  {
    path: '/favorite_details',
    name: '收藏夹详情',
    component: favorite_details,
  },
  {
    path: '/create_avorites',
    name: '创建收藏夹',
    component: create_avorites,
  },
  {
    path: '/browse_records',
    name: '浏览记录',
    component: browse_records,
  },
  {
    path: '/click_details',
    name: '点击详情页',
    component: click_details,
  },
  {
    path: '/set_possword',
    name: '设置登录密码',
    component: set_possword,
  },
  {
    path: '/modify_possword',
    name: '修改登录密码',
    component: modify_possword,
  },
  {
    path: '/login_cover',
    name: '登录封面',
    component: login_cover,
  },
  {
    path: '/login',
    name: '登录',
    component: login,
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//路由守卫
router.beforeEach((to, from, next) => {
  // 如果地址不是授权页就看有没有token
  if (to.path != "/login") {
    //如果有token 直接跳转 没token 跳转到授权页
    if (localStorage.getItem("token")) {
      next()
    } else {
      next("/login")
    }
    // 如果是登录页就正常往下走
  } else {
    next()
  }
})



export default router