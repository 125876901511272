
import axios from "axios"
import router from '@/router'
import Vue from "vue";
import Vant from "vant"
import "vant/lib/index.css"

Vue.use(Vant);



//拦截器
//请求拦截响应拦截
//直接引用axios
axios.interceptors.request.use(config => {
    return config
}), err => {
    console.log(err);
    return err
}

axios.interceptors.response.use(response => { 
    // token时效 跳转到登录并在url后面拼接个flag=1
    if (response.data.code == 201) {
        router.push({
            path: "/login",
            query: {
                flag: 1
            }
        })
        localStorage.removeItem("token")
    }

    return response
}), err => {
    console.log(err);
    return err
}

export default axios