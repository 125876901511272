<template>
    <div class="wain sc" id="show">
        <!-- 头部-->
        <header class="hcc">
            <img src="@/assets/img/leftarrow.png" alt="" class="leftarrow" @click="backArrow">
            <p>收藏夹详情</p>
        </header>

        <!-- 主体 -->
        <section class="sc">
            <!-- 标题 -->
            <div class="title hc">
                <p>共创建<span>{{ this.$route.query.number }}</span>个收藏夹</p>
            </div>
            
            <!-- 内容一部分 -->
            <div class="content sc">

                <!-- 编辑 删除内容 -->
                <div class="editcontent hca">
                    <!-- 图片 -->
                    <div class="editimg">
                        <img src="@/assets/img/stars.png" alt="" srcset="" v-show="imgone" class="imgone">
                        <img :src="img" alt="" srcset="" v-show="imgtwo" class="imgtwo">
                    </div>
                    <!-- 文字 -->
                    <div class="editword">
                        <div class="editwordone">{{ this.$route.query.name }}</div>
                        <div class="editwordtwo"><span>{{ number + " 篇内容" }}</span></div>
                        <!-- 按钮盒子 -->
                        <div class="buttomcase hc" v-show="buttomcase">
                            <button class="edit" @click="edit">编辑</button>
                            <button class="delete" @click="getDelete">删除</button>
                        </div>
                    </div>
                </div>

                <!-- 下方文字内容 -->
                <div class="downword">
                    <div class="wordone">
                    </div>
                    <div class="wordtwo">
                        {{ "创建时间 " + this.$route.query.time.slice(0, 17) }}
                    </div>

                </div>
            </div>

            <!-- 内容列表 -->
            <div :class="r.num_read == null || r.num_read == '' ? hide : contentlist" v-for="r in list" :key="r.id">
                <img :src="r.image" alt="" @click="jump(r.code)" :class="r.image ? imgDisplayBlock : imgDisplayNone">
                <!-- 文字盒子 -->
                <div :class="r.image ? wordcaseOne : wordcaseTwo">
                    <div class="wordone" @click="jump(r.code)">
                        {{ r.name }}
                    </div>
                    <div class="wordthree hca">
                        <img src="@/assets/img/eyes.png" alt="" srcset="" @click="jump(r.code)">
                        <p @click="jump(r.code)">{{ r.num_read }}</p>
                        <span @click="jump(r.code)">{{ r.username }}</span>
                        <button @click="getFavoriteDelete(r.code)">删除</button>
                    </div>

                </div>
            </div>
            <!-- 没有了 -->
            <div class="noword hca">
                <img src="@/assets/img/horizontal_line.png" alt="" srcset="">
                <p>没有了~</p>
                <img src="@/assets/img/horizontal_line.png" alt="" srcset="">
            </div>

            <!-- 文件夹删除确认框蒙版 -->
            <div class="determinecase hcc" v-show="determine">
                <div class="determine scc">
                    <p>确定要删除吗</p>
                    <div class="buttoncase hca">
                        <button class="cancelbutton" @click="cancelbutton">取消</button>
                        <button class="confirmbutton" @click="confirmbutton">确认</button>
                    </div>
                </div>

            </div>
            <!-- 文章确认删除框蒙版 -->
            <div class="determinecasearticle hcc" v-show="determinearticle">
                <div class="determinearticle scc">
                    <p>确定要删除吗</p>
                    <div class="buttoncasearticle hca">
                        <button class="cancelbuttonarticle" @click="cancelbuttonarticle">取消</button>
                        <button class="confirmbuttonarticle" @click="confirmbuttonarticle">确认</button>
                    </div>
                </div>

            </div>

            <!-- 编辑弹窗 -->
            <div class="editmask scc" v-show="editmask">
                <!-- 编辑框文字 -->
                <div class="editword scc">
                    <div class="editinput hc">
                        <p>修改名称：</p>
                        <input type="text" v-model="editinput">
                    </div>
                    <!-- 按钮盒子 -->
                    <div class="editbuttoncase hcc">
                        <button class="editcancel" @click="rejectedit">取消</button>
                        <button class="editdetermine" @click="sealedit">确定</button>

                    </div>

                </div>

            </div>
        </section>
    </div>

</template>

<script>
import watermark from '@/watermark/watermark';

export default {
    name: 'favorite_details',
    
    components: {

    },
    data() {
        return {
            list: "",
            number: "",
            time: "",
            determine: false,
            determinearticle: false,
            modifyinput: false,
            affirmflag: false,
            buttomcase: true,
            img: this.$route.query.img,
            imgone: true,
            imgtwo: false,
            editmask: false,
            editinput: this.$route.query.name,
            imgDisplayBlock: "imgBlock",
            imgDisplayNone: "imgNone",
            wordcaseOne: "contentwordcase",
            wordcaseTwo: "contentwordcaseTwo",
            hide: "hide",
            contentlist: "contentlist"
        }
    },
    mounted() {
        this.wxfx();
    
        //判断有没有水印
        if (localStorage.getItem("shuiyin_status") == 1) {
            watermark.set(
                localStorage.getItem("logo")
            )
        } else {
            watermark.out(
                localStorage.getItem("logo")
            )
        }
        if (this.$route.query.img) {
            this.imgone = false,
                this.imgtwo = true
        } else {
            this.imgone = true,
                this.imgtwo = false
        }
        // 调取收藏夹文章列表接口
        this.$axios({
            url: "http://y163.njhxzx.com/data/Article/getFavoritesNews",
            methods: "POST",
            params: {
                token: localStorage.getItem("token"),
                favorites_id: this.$route.query.favoritesid
            }
        }).then(res => {

            // 如果登录成功 
            if (res.data.code == 1) {
                this.list = res.data.data.list
                this.number = this.list.length
            }

        }).catch(err => {

        })
    },

    methods: {
        // 取消编辑按钮
        rejectedit() {
            this.editmask = false
        },
        // 确认编辑按钮
        sealedit() {
            // 调取修改文件夹接口
            this.$axios({
                url: "http://y163.njhxzx.com/data/Article/updateFavorites",
                methods: "post",
                params: {
                    token: localStorage.getItem("token"),
                    id: this.$route.query.favoritesid,
                    name: this.editinput
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.$router.go(-1)
                }

            }).catch(err => {

            })

        },
        // 点击跳转相应文章
        jump(e) {
            localStorage.setItem("path", "/my_collection")
            this.$router.push({
                path: "/click_details",
                query: {
                    codeid: e

                },

            })
        },
        // 返回上一级
        backArrow() {
            this.$router.push({
                path: "/my_collection"
            })
        },
        // 点击编辑显示输入框
        edit() {
            this.editmask = true
        },
        //编辑取消按钮
        scrap() {
            this.modifyinput = false,
                this.affirmflag = false,
                this.modify = true
            this.buttomcase = true
        },
        // 编辑确认按钮 调取编辑接口
        affirm() {
            this.$axios({
                url: "http://y163.njhxzx.com/data/Article/updateFavorites",
                methods: "post",
                params: {
                    token: localStorage.getItem("token"),
                    id: this.$route.query.favoritesid,
                    name: this.revise
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.$router.push({
                        path: "/my_collection"
                    })
                }


            }).catch(err => {

            })
        },
        // 点击取消隐藏蒙版
        cancelbuttonarticle() {
            this.determinearticle = false
        },
        // 点击确认调取删除接口
        confirmbuttonarticle() {
            this.$axios({
                url: "http://y163.njhxzx.com/data/Article/delFavoritesNews",
                methods: "post",
                params: {
                    token: localStorage.getItem("token"),
                    favorites_id: this.$route.query.favoritesid,
                    code: localStorage.getItem("articlecode")
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.determinearticle = false

                    // 调取收藏夹文章列表接口
                    this.$axios({
                        url: "http://y163.njhxzx.com/data/Article/getFavoritesNews",
                        methods: "POST",
                        params: {
                            token: localStorage.getItem("token"),
                            favorites_id: this.$route.query.favoritesid
                        }
                    }).then(res => {
                        // 如果登录成功 
                        if (res.data.code == 1) {
                            this.list = res.data.data.list
                            this.number = this.list.length
                            this.$router.go(-1)

                        }

                    }).catch(err => {

                    })

                }
            }).catch(err => {

            })
        },
        // 取消按钮
        cancelbutton() {
            this.determine = false
        },

        // 确认按钮调取删除收藏夹接口
        confirmbutton() {
            this.$axios({
                url: "http://y163.njhxzx.com/data/Article/delFavorites",
                methods: "post",
                params: {
                    token: localStorage.getItem("token"),
                    id: this.$route.query.favoritesid
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.$router.push({
                        path: "/my_collection"
                    })


                }


            }).catch(err => {

            })
        },
        // 调取确认框
        getDelete() {
            this.determine = true
        },
        // 调取删除文件夹下方文章接口
        getFavoriteDelete(e) {
            localStorage.setItem("articlecode", e)
            this.determinearticle = true

        }
    },
}
</script>



<style lang="scss" scoped>
#show {
    position: relative;
    z-index: 999;
    overflow: hidden;
}

// 外边大盒子
.wain {
    width: 100%;
    height: 100%;


    // 头部
    header {
        width: 750rem;
        height: 110rem;
        background-color: #FA784F;
        position: relative;

        img {
            width: 21rem;
            height: 38rem;
            margin-left: 20rem;
        }

        p {
            font-size: 38rem;
            color: #fff;
        }

        .leftarrow {
            position: absolute;
            left: 20rem;

        }
    }

    // 主体
    section {
        width: 100%;
        height: 100%;

        // 编辑蒙版
        .editmask {
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: #0000002d;
            top: 0;

            .editword {
                width: 500rem;
                height: 300rem;
                background-color: #fff;
                border-radius: 30rem;

                .editinput {
                    margin-top: 70rem;

                    p {
                        font-size: 25rem;
                    }

                    input {
                        width: 264rem;
                        height: 50rem;
                        background-color: #f5f5f5;
                        font-size: 25rem;
                        padding-left: 10rem;
                    }
                }


            }

            // 按钮盒子
            .editbuttoncase {
                width: 70%;
                height: 100rem;
                // background-color: #FA784F;
                margin-top: 11rem;

                .editcancel {
                    width: 118rem;
                    height: 64rem;
                    background-color: #fff;
                    font-size: 25rem;
                    border-radius: 50rem;
                }

                .editdetermine {
                    width: 118rem;
                    height: 64rem;
                    background-color: #fff;
                    font-size: 25rem;
                    border-radius: 50rem;
                    color: #FF784F;
                }
            }
        }

        // 标题
        .title {
            width: 100%;
            height: 85rem;

            p {
                margin-left: 45rem;
                font-size: 27rem;
                color: #999999;

            }

            span {
                font-size: 27rem;
                color: #999999;
            }
        }

        // 内容一部分
        .content {
            width: 100%;
            height: 370rem;

            // 编辑删除内容
            .editcontent {
                width: 670rem;
                height: 226rem;

                // 图片
                .editimg {
                    width: 224rem;
                    height: 224rem;
                    background-color: #FFF1ED;
                    border-radius: 10rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;



                    .imgone {
                        width: 126rem;
                        height: 120rem;
                    }

                    .imgtwo {
                        width: 100%;
                        height: 100%;
                    }

                }

                // 文字
                .editword {
                    width: 415rem;
                    height: 100%;
                    padding: 10rem;

                    .editwordone {
                        font-size: 30rem;
                        font-weight: 600;
                    }

                    .editwordtwo {
                        font-size: 23rem;
                        color: #666666;
                        margin-top: 30rem;
                    }




                    input {
                        width: 250rem;
                        height: 46rem;
                        background-color: #f5f5f5;
                        font-size: 30rem;
                        padding-left: 10rem;
                    }

                }

                // 按钮盒子
                .buttomcase {
                    width: 100%;
                    height: 50rem;
                    margin-top: 50rem;

                    .edit {
                        width: 80rem;
                        height: 40rem;
                        background-color: #FA784F;
                        color: #fff;
                        border-radius: 100rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 22rem;
                    }

                    .delete {
                        width: 80rem;
                        height: 40rem;
                        background-color: #fff;
                        color: #FA784F;
                        border: 1rem solid #FA784F;
                        border-radius: 100rem;
                        margin-left: 45rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 22rem;
                    }
                }


                // 编辑确认按钮盒子
                .confirmbuttoncase {
                    width: 60%;
                    height: 50rem;
                    margin-top: 50rem;
                    margin-left: -20rem;


                    // 取消按钮
                    .scrap {
                        width: 80rem;
                        height: 40rem;
                        font-size: 20rem;
                        border-radius: 300rem;
                    }

                    .affirm {
                        width: 80rem;
                        height: 40rem;
                        font-size: 20rem;
                        border-radius: 300rem;
                        color: #fff;
                        background-color: #FA784F;
                    }
                }
            }

            // 下方文字内容
            .downword {
                width: 100%;
                height: 144rem;
                border-bottom: 1rem solid #0000000d;
                padding-left: 50rem;

                .wordone {
                    width: 100%;
                    height: 40rem;
                    font-size: 21rem;
                    color: #999999;
                    margin-top: 20rem;
                }

                .wordtwo {
                    width: 100%;
                    height: 40rem;
                    font-size: 18rem;
                    color: #FA784F;
                    margin-top: 20rem;
                }
            }
        }

        .hide {
            display: none;
        }

        // 内容列表
        .contentlist {
            width: 650rem;
            height: 220rem;
            // background-color: aquamarine;
            margin-top: 37rem;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .imgBlock {
                width: 200rem;
                height: 200rem;
            }

            .imgNone {
                display: none;
            }

            // 文字盒子 第一种形态
            .contentwordcase {
                width: 363rem;
                height: 100%;
                padding: 10rem;

                .wordone {
                    font-size: 30rem;
                    font-weight: 600;
                }

                .wordtwo {
                    font-size: 22rem;
                    color: #999999;
                    margin-top: 17rem;
                }

                .wordthree {
                    width: 100%;
                    height: 40rem;
                    margin-top: 115rem;

                    img {
                        width: 30rem;
                        height: 20rem;
                        margin-left: -42rem;
                    }

                    p {
                        font-size: 21rem;
                        color: #999;
                        margin-left: -20rem;
                    }

                    span {
                        font-size: 21rem;
                        color: #999;
                        // margin-left: -22rem;


                    }

                    button {
                        width: 80rem;
                        height: 40rem;
                        background-color: #fff;
                        color: #FA784F;
                        border: 1rem solid #FA784F;
                        border-radius: 100rem;
                        margin-left: 45rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 22rem;
                    }
                }
            }

            // 文字盒子 第二种形态
            .contentwordcaseTwo {
                width: 430rem;
                height: 100%;
                padding: 10rem;
                margin-left: -194rem;

                .wordone {
                    font-size: 30rem;
                    font-weight: 600;
                }

                .wordtwo {
                    font-size: 22rem;
                    color: #999999;
                    margin-top: 17rem;
                }

                .wordthree {
                    width: 93%;
                    height: 40rem;
                    margin-top: 100rem;

                    img {
                        width: 30rem;
                        height: 20rem;
                    }

                    p {
                        font-size: 21rem;
                        color: #999;
                        margin-left: -20rem;
                    }

                    span {
                        font-size: 21rem;
                        color: #999;
                        // margin-left: -22rem;


                    }

                    button {
                        width: 80rem;
                        height: 40rem;
                        background-color: #fff;
                        color: #FA784F;
                        border: 1rem solid #FA784F;
                        border-radius: 100rem;
                        margin-left: 45rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 22rem;
                    }
                }
            }
        }

        // 没有了
        .noword {
            width: 465rem;
            height: 40rem;
            margin-top: 50rem;
            color: #999;
            font-size: 22rem;
        }

        // 文件夹确认框
        .determinecase {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            background-color: #0000002d;
            z-index: 999;

            .determine {
                width: 500rem;
                height: 300rem;
                background-color: #fff;
                border-radius: 50rem;

                p {
                    font-size: 35rem;
                }

                .buttoncase {
                    width: 70%;
                    height: 75rem;
                    margin-top: 30rem;
                    // background-color: #FF784F;
                    // button{
                    //     width: 100rem;
                    //     height: 60rem;
                    //     font-size: 25rem;

                    // }
                    .cancelbutton {
                        width: 100rem;
                        height: 60rem;
                        font-size: 25rem;
                        border-radius: 300rem;
                    }

                    .confirmbutton {
                        width: 100rem;
                        height: 60rem;
                        font-size: 25rem;
                        border-radius: 300rem;
                        color: #fff;
                        background: #FF784F;
                    }
                }
            }

        }

        // 文章确认框
        .determinecasearticle {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            background-color: #0000002d;
            z-index: 999;

            .determinearticle {
                width: 500rem;
                height: 300rem;
                background-color: #fff;
                border-radius: 50rem;

                p {
                    font-size: 35rem;
                }

                .buttoncasearticle {
                    width: 70%;
                    height: 75rem;
                    margin-top: 30rem;
                    // background-color: #FF784F;
                    // button{
                    //     width: 100rem;
                    //     height: 60rem;
                    //     font-size: 25rem;

                    // }
                    .cancelbuttonarticle {
                        width: 100rem;
                        height: 60rem;
                        font-size: 25rem;
                        border-radius: 300rem;
                    }

                    .confirmbuttonarticle {
                        width: 100rem;
                        height: 60rem;
                        font-size: 25rem;
                        border-radius: 300rem;
                        color: #fff;
                        background: #FF784F;
                    }
                }
            }

        }

    }
}
</style>
