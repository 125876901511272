import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import wx from "weixin-js-sdk"
import "./assets/common.css"
import "./assets/font/iconfont.css"
import axios from "axios"
import qs from "qs"
import {Toast} from 'vant';
import Vant from 'vant';
import 'vant/lib/index.css';
import VueClipboard from 'vue-clipboard2'
import common from './assets/js/common.js'




Vue.prototype.$axios = axios
Vue.prototype.$qs = qs
Vue.prototype.wx = wx
Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(Vant);
Vue.use(Toast);
Vue.use(common);

function gotoURL(callback){
  window.onpopstate = null;
  window.onpopstate = function(){
    callback()
  }
}
Vue.prototype.gotoURL = gotoURL








new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')