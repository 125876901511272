<template>
  <div id="app" v-cloak>
    <router-view></router-view>
  </div>
</template>
<script>
import myFooter from "./components/myFooter"
import mine from "./views/mine"
import index from "./views/index"
import my_collection from "./views/my_collection"
import favorite_details from "./views/favorite_details"
import create_avorites from "./views/create_avorites"
import browse_records from "./views/browse_records"
import click_details from "./views/click_details"
import set_possword from "./views/set_possword"
import modify_possword from "./views/modify_possword"
import login_cover from "./views/login_cover"
import login from "./views/login"

export default {
  name: "App",
  components: {  
    myFooter,
    mine,
    index,
    my_collection,
    favorite_details,
    create_avorites,
    browse_records,
    click_details,
    set_possword,
    modify_possword,
    login_cover,
    login,

  }
}

</script>
<style lang="scss" scoped>
i {
  font-size: 30rem;
}

[v-cloac] {
  display: none;
}
</style>


