<template>
    <div class="wain sc" id="show">
        <!-- 头部-->
        <header class="hcc">
            <img src="@/assets/img/leftarrow.png" alt="" class="leftarrow" @click="backArrow">
            <p>创建收藏夹</p>
        </header>

        <!-- 主体 -->
        <section class="sc">
            <input type="text" class="name" placeholder="请输入收藏夹名称" v-model="create">
            <textarea class="details" placeholder="请添加描述（可选）"></textarea>
            <button @click="getAvorites">创建</button>
        </section>
    </div>

</template>

<script>
import watermark from '@/watermark/watermark'

export default {
    name: 'my_collection',

    components: {

    },
    data() {
        return {
            create: ""
        }
    },
    mounted() {
        this.wxfx();
    
        let that = this;
        pushHistory()  // 必须存在
        that.gotoURL(function () {
            pushHistory()  // 必须存在
            that.$router.push({
                path: "/my_collection"
            })
        })
        //判断有没有水印
        if (localStorage.getItem("shuiyin_status") == 1) {
            watermark.set(
                localStorage.getItem("logo")
            )
        } else {
            watermark.out(
                localStorage.getItem("logo")
            )
        }


    },

    methods: {
        // 返回上一级
        backArrow() {
            this.$router.push({
                path: "/my_collection"
            })
        },
        // 点击调取创建文件夹接口
        getAvorites() {
            this.$axios({
                url: "http://y163.njhxzx.com/data/Article/addFavorites",
                methods: "POST",
                params: {
                    token: localStorage.getItem("token"),
                    name: this.create
                }
            }).then(res => {
                this.$toast.fail(res.data.info)
                if (res.data.code == 1) {
                    this.$toast.success('创建成功')
                    setTimeout(() => {
                        this.$router.push({
                            path: "/my_collection",

                        })
                    }, 2000);
                }

            }).catch(err => {

            })
        }
    },
}
</script>



<style lang="scss" scoped>
#show {
    position: relative;
    z-index: 999;
    overflow: hidden;
    height: 100vh;
}

// 外边大盒子
.wain {
    width: 100%;
    height: 100%;

    // 头部
    header {
        width: 750rem;
        height: 110rem;
        background-color: #FA784F;
        position: relative;

        img {
            width: 21rem;
            height: 38rem;
            margin-left: 20rem;
        }

        p {
            font-size: 38rem;
            color: #fff;
        }

        .leftarrow {
            position: absolute;
            left: 20rem;

        }
    }

    // 主体
    section {
        width: 100%;
        align-items: flex-start;

        .name {
            width: 511rem;
            height: 81rem;
            background-color: #EEEEEE;
            border-radius: 200rem;
            padding-left: 50rem;
            margin-top: 50rem;
            margin-left: 58rem;
            font-size: 28rem;
        }

        .details {
            width: 640rem;
            height: 288rem;
            background-color: #EEEEEE;
            resize: none;
            padding: 40rem;
            margin-left: 58rem;
            margin-top: 34rem;
            border-radius: 10rem;
            font-size: 28rem;
        }

        button {
            width: 470rem;
            height: 85rem;
            background-color: #FA784F;
            color: #fff;
            border-radius: 100rem;
            margin-left: 155rem;
            margin-top: 131rem;
            font-size: 35rem;
        }



    }
}
</style>
