<template>
  <div class="warnBox">
    <ul class="footer">
      <router-link tag="li" :to="r.path" v-for="r in routeList" :key="r.path">
        <i :class="r.icon"></i>
        {{ r.name }}
      </router-link>
    </ul>
  </div>
</template>


<script>

export default {
  name: 'myFooter',
  
  data() {
    return {
      routeList: []
    }

  },
  mounted() {
    this.routeList = this.$router.options.routes.slice(1, 3)

  },
  methods: {

  },

}

</script>


<style scoped lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

.footer {
  position: fixed;
  bottom: 0rem;
  height: 100rem;
  left: 0;
  display: flex;
  align-items: center;
  height: 118rem;
  width: 100%;
  justify-content: space-around;
  background: #fff;
  border-top: 1rem solid #0000000d;

  .router-link-active {
    color: #FF784F;
  }



  li {
    list-style: none;
    color: #000;
    font-size: 25rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50rem;
    font-size: 22rem;
    height: 90rem;



    .icon {
      font-size: 40rem;
    }

  }

}
</style>
