<template>
    <div class="wain sc" id="show">

        <!-- 头部-->
        <header class="hcc">
            <img src="@/assets/img/leftarrow.png" alt="" class="leftarrow" @click="backArrow">
            <p>{{ name }}</p>
        </header>
        <!-- 主体 -->
        <section class="sc">
            <!-- 详情盒子 -->
            <div class="detailscase">
                <!-- 详情 -->
                <div class="details">
                    <p class="title">{{ name }}</p>
                    <div class="wordcase hc">
                        <p>{{ username }}</p>
                        <p class="create">{{ time }}</p>
                    </div>

                    <!-- 收藏盒子 -->
                    <div class="collectioncase hca">
                        <img src="../assets/img/no_collection.png" alt="" srcset="" v-show="nocollection"
                            @click="addCollection">
                        <img src="../assets/img/collection.png" alt="" srcset="" v-show="collection"
                            @click="removeCollection">
                        <p>{{ num_collect }}</p>
                    </div>
                </div>

                <!-- 内容 -->
                <div class="content sc">
                    <!-- 内容盒子 -->
                    <div class="sc">
                        <div class="contentCase " id="img_arr">
                            <!-- 富文本内容 -->
                            <div v-html="content" @click="showImg(content)">
                                <!-- 长按事件放上面 -->
                                <!--  @touchstart="gtouchstart(content,$event)" -->
                            </div>
                        </div>
                    </div>


                    <!-- 底部声明 -->
                    <div class="tipfoot">
                        <p>特别说明：本链接中的所有内容均从网络公开资料检索而来，仅供交流使用；如有相关权利人有异议或认为存在侵权事宜的，请及时联系删除。</p>
                        <p>联系方式:con202212@163.com</p>
                    </div>

                </div>
            </div>

            <!-- 弹窗 -->
            <div class="popup" v-show="popupflag">
                <div class="popupcase">

                    <!-- 标题 -->
                    <div class="popuptitle hcc">
                        <p>选择文件夹</p>
                    </div>

                    <!-- 下方内容 -->
                    <div class="popuplabel hcaw">
                        <div class="labellist hcc" v-for="r in favoriteslist" :key="r.id" @click="getLabel(r.id)">
                            {{ r.name }}
                        </div>

                        <!-- 空盒子 -->
                        <div class="labellistspace hcc">

                        </div>
                        <div class="labellistspace hcc">

                        </div>

                    </div>

                    <!-- 按钮盒子 -->
                    <div class="buttoncase hca">
                        <div class="cancel hcc" @click="cancel">
                            取消
                        </div>
                        <div class="determine hcc" @click="determine">
                            确定
                        </div>
                    </div>
                </div>
            </div>
            <!-- 链接复制确认 -->
            <!-- <div class="link hcc" v-show="linkFlag">
                <div class="linkCase scc">
                    <div>
                        <p>确认要复制链接吗？</p>
                    </div>
                    <div class="hca buttonCase">
                        <button class="scrap" @click="scrap">取消</button>
                        <button class="ack" v-clipboard:copy="copy" v-clipboard:success="onCopy"
                            v-clipboard:error="onError">确认</button>
                    </div>

                </div>

            </div> -->
        </section>
    </div>

</template>

<script>
import watermark from '@/watermark/watermark'
import Viewer from 'viewerjs'
import 'viewerjs/dist/viewer.css'


export default {
    name: 'click_details',

    components: {

    },
    data() {
        return {
            flag: false,
            nocollection: true,
            collection: false,
            popupflag: false,
            list: "",
            content: "",
            code: "",
            detailslist: "",
            givelist: "",
            name: "",
            cover: "",
            time: "",
            num_collect: "",
            code: "",
            username: "",
            favoriteslist: "",
            html: "",
            // copy: localStorage.getItem("link"),
            // linkFlag: false
            labelid: "",
            cancellabelid: ""
        }
    },
    
    
    mounted() {
        this.wxfx();
        //判断有没有水印
        if (localStorage.getItem("shuiyin_status") == 1) {
            watermark.set(
                localStorage.getItem("logo")
            )
        } else {
            watermark.out(
                localStorage.getItem("logo")
            )
        }

        // 提取文章详情内容接口
        this.$axios({
            url: "http://y163.njhxzx.com/data/article/detail",
            methods: "get",
            params: {
                token: localStorage.getItem("token"),
                code: this.$route.query.codeid
            }
        }).then(res => {
            if (res.data.code == 1) {
                this.detailslist = res.data.data.list
                this.name = this.detailslist.name
                this.time = this.detailslist.create_at.slice(0, 12)
                this.cover = this.detailslist.cover
                this.num_collect = this.detailslist.num_collect
                this.content = this.detailslist.content;
                this.code = this.detailslist.code
                this.username = this.detailslist.username
            }
            // 判断有没有收藏
            if (this.detailslist.my_coll_state == 1) {
                this.nocollection = false,
                    this.collection = true
            } else {
                this.nocollection = true,
                    this.collection = false
            }
        }).catch(err => {

        })

        // 调取文件夹数接口
        this.$axios({
            url: "http://y163.njhxzx.com/data/Article/getFavorites",
            methods: "post",
            params: {
                token: localStorage.getItem("token"),
            }
        }).then(res => {
            if (res.data.code == 1) {
                this.favoriteslist = res.data.data.list
            }
            // 如果只有一个文件夹，将文件夹id赋值给label
            if (this.favoriteslist.length == 1) {
                this.labelid = this.favoriteslist[0].id
            }
        }).catch(err => {

        })
    },

    methods: {
        // //长按事件（起始）
        // gtouchstart(item, event) {
        //     var self = this;
        //     this.timeOutEvent = setTimeout(function () {
        //         var pro = event.currentTarget;
        //         self.longPress(item);
        //         self.linkFlag = true
        //         localStorage.setItem("link", event.target.innerHTML)
        //     }, 1000); //这里设置定时器，定义长按500毫秒触发长按事件
        //     return false;
        // },

        // //真正长按后应该执行的内容
        // longPress(val) {
        //     let img = document.querySelectorAll("a")
        //     let img_arr = []
        //     img.forEach((item, index) => {
        //         img_arr.push(item.getAttribute("href"))
        //     })

        //     const ViewerDom = document.getElementById('img_arr');
        //     const viewer = new Viewer(ViewerDom, {

        //     });

        // },
        // // 复制成功时的回调函数
        // onCopy(e) {
        //     this.linkFlag = false
        //     this.$toast.success('复制成功')
        //     this.$router.go(0)

        // },
        // // 复制失败时的回调函数
        // onError(e) {
        //     this.$toast.fail('请选择链接复制')
        // },
        // // 取消复制
        // scrap() {
        //     this.linkFlag = false
        // },
        
        // 点击放大图片
        showImg() {
            let img = document.querySelectorAll(".image_resized")
            let img_arr = []
            img.forEach((item, index) => {
                img_arr.push(item.getAttribute("src"))
            })

            const ViewerDom = document.getElementById('img_arr');
            const viewer = new Viewer(ViewerDom, {


            });
        },


        // 点击返回上一级
        backArrow() {
            this.$router.push({
                path: localStorage.getItem("path")
            })
        },
        // 点击将当前文件夹编号放到变量中
        getLabel(e) {
            this.labelid = e
        },

        addCollection() {
            // 如果只有一个收藏夹
            if (this.favoriteslist.length == 1) {
                // 调取收藏接口
                this.$axios({
                    url: "http://y163.njhxzx.com/data/Article/addFavoritesNews",
                    methods: "post",
                    params: {
                        token: localStorage.getItem("token"),
                        code: this.$route.query.codeid,
                        favorites_id: this.labelid
                    }
                }).then(res => {
                    if (res.data.code == 1) {
                        this.$toast.success('收藏成功')
                        // 重新渲染收藏数据
                        this.$axios({
                            url: "http://y163.njhxzx.com/data/article/detail",
                            methods: "get",
                            params: {
                                token: localStorage.getItem("token"),
                                code: this.$route.query.codeid
                            }
                        }).then(res => {
                            if (res.data.code == 1) {
                                this.detailslist = res.data.data.list
                                this.num_collect = this.detailslist.num_collect

                            }
                            // 判断有没有点赞
                            if (this.detailslist.my_coll_state == 1) {
                                this.nocollection = false,
                                    this.collection = true
                            } else {
                                this.nocollection = true,
                                    this.collection = false
                            }


                        }).catch(err => {

                        })



                    }
                }).catch(err => {

                })

            } else {
                this.popupflag = true
            }

        },
        //取消收藏
        removeCollection() {
            // 提取文章详情内容接口 获取收藏到哪个文件夹的id
            this.$axios({
                url: "http://y163.njhxzx.com/data/article/detail",
                methods: "get",
                params: {
                    token: localStorage.getItem("token"),
                    code: this.$route.query.codeid
                }
            }).then(res => {
                if (res.data.code == 1) {
                    // 拿到收藏到哪个收藏夹id
                    this.cancellabelid = this.detailslist.favorites_id[0]
                    // 调取取消收藏接口
                    this.$axios({
                        url: "http://y163.njhxzx.com/data/Article/delFavoritesNews",
                        methods: "post",
                        params: {
                            token: localStorage.getItem("token"),
                            code: this.$route.query.codeid,
                            favorites_id: this.cancellabelid
                        }
                    }).then(res => {
                        // 重新渲染
                        if (res.data.code == 1) {
                            this.$toast.success('取消收藏成功')
                            this.$axios({
                                url: "http://y163.njhxzx.com/data/article/detail",
                                methods: "get",
                                params: {
                                    token: localStorage.getItem("token"),
                                    code: this.$route.query.codeid
                                }
                            }).then(res => {
                                if (res.data.code == 1) {
                                    this.detailslist = res.data.data.list
                                    this.num_collect = this.detailslist.num_collect

                                }
                                // 判断有没有点赞
                                if (this.detailslist.my_coll_state == 1) {
                                    this.nocollection = false,
                                        this.collection = true
                                } else {
                                    this.nocollection = true,
                                        this.collection = false
                                }


                            }).catch(err => {

                            })
                        }



                    }).catch(err => {

                    })

                }

            }).catch(err => {

            })



        },
        // 点击弹窗取消
        cancel() {
            this.popupflag = false

        },

        //多个文件夹点击收藏 调取接口
        determine() {
            if (this.detailslist.my_coll_state == 0) {
                // 调取收藏接口
                this.$axios({
                    url: "http://y163.njhxzx.com/data/Article/addFavoritesNews",
                    methods: "post",
                    params: {
                        token: localStorage.getItem("token"),
                        code: this.$route.query.codeid,
                        favorites_id: this.labelid

                    }
                }).then(res => {
                    if (res.data.code == 0) {
                        this.$toast.fail('请选择你要收藏的文件夹')
                    }
                    if (res.data.code == 1) {
                        this.$toast.success('收藏成功')
                        this.$axios({
                            url: "http://y163.njhxzx.com/data/article/detail",
                            methods: "get",
                            params: {
                                token: localStorage.getItem("token"),
                                code: this.$route.query.codeid
                            }
                        }).then(res => {
                            if (res.data.code == 1) {
                                this.detailslist = res.data.data.list
                                this.num_collect = this.detailslist.num_collect

                            }
                            // 判断有没有点赞
                            if (this.detailslist.my_coll_state == 1) {
                                this.nocollection = false,
                                    this.collection = true
                            } else {
                                this.nocollection = true,
                                    this.collection = false
                            }


                        }).catch(err => {

                        })



                    }
                }).catch(err => {

                })

            }
            this.popupflag = false
        },
        label() {

        }
    },
}
</script>



<style lang="scss" scoped>
.van-toast .van-toast--middle .van-toast--success {
    width: 600rem;
    height: 600rem;
}

#show {
    position: relative;
    z-index: 999;
    overflow: hidden;
}

.wain {
    width: 100%;
    height: 100%;


    // 头部
    header {
        width: 750rem;
        height: 110rem;
        background-color: #FA784F;
        position: relative;

        img {
            width: 21rem;
            height: 38rem;
            margin-left: 20rem;
        }

        p {
            font-size: 38rem;
            color: #fff;
        }

        .leftarrow {
            position: absolute;
            left: 20rem;

        }
    }

    // 主体
    section {
        width: 100%;
        position: relative;

        // 复制蒙版
        .link {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            background-color: #0000002d;

            .linkCase {
                width: 463rem;
                height: 300rem;
                position: absolute;
                top: 524rem;
                left: 156rem;
                background-color: #fff;
                border-radius: 35rem;

                .buttonCase {
                    width: 60%;
                    margin-top: 20rem;

                    .scrap {
                        border-radius: 30rem;
                    }

                    .ack {
                        border-radius: 30rem;
                        background-color: #FA784F;
                        color: #fff;
                    }
                }

                p {
                    font-size: 30rem;
                }

                button {
                    width: 100rem;
                    height: 60rem;
                }
            }

        }

        .detailscase {
            width: 100%;

            //详情
            .details {
                width: 100%;
                height: 185rem;
                border-bottom: 1rem solid #0000002d;
                display: flex;
                flex-direction: column;
                align-content: flex-start;
                padding: 30rem;
                position: relative;

                .title {
                    font-size: 34rem;
                    font-weight: 600;
                    margin-left: 16rem;
                }

                .wordcase {
                    width: 62%;
                    height: 40rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    margin-top: 40rem;
                    margin-left: 16rem;
                    justify-content: flex-start;



                    p {
                        color: #414141;
                        font-size: 24rem;
                    }

                    .create {
                        margin-left: 30rem;
                        // width: 100rem;
                        white-space: nowrap;
                    }
                }

                // 点赞盒子
                .collectioncase {
                    width: 90rem;
                    height: 50rem;
                    position: absolute;
                    top: 35rem;
                    right: 80rem;


                    img {
                        width: 34rem;
                        height: 34rem;
                    }

                    p {
                        font-size: 29rem;
                        margin-left: 5rem;
                        color: #999;
                    }
                }

                // 收藏盒子 
                .tuckcase {
                    width: 90rem;
                    height: 50rem;
                    position: absolute;
                    right: 83rem;
                    top: 107rem;

                    p {
                        color: #000;
                    }
                }
            }


            .content {
                width: 100%;
                height: 100%;

                // 内容盒子
                .contentCase {
                    width: 90%;
                    margin-top: 30rem;

                    // 深度修改内容
                    &::v-deep img {
                        width: 100%;
                    }

                    &::v-deep .image_resized {
                        margin-left: 300rpx;
                    }

                    &::v-deep span {
                        word-break: break-word;
                    }

                    &::v-deep a {
                        word-break: break-word;
                        color: blue;
                        text-decoration: underline
                    }



                    &::v-deep p {
                        word-break: break-word;
                        font-size: 30rem;

                        img {
                            width: 750rpx;
                        }


                    }

                    &::v-deep h2 {
                        word-break: break-word;
                    }

                }



            }

        }

        // 底部提示
        .tipfoot {
            width: 700rem;
            height: 180rem;
            background-color: #999;
            border-radius: 10rem;
            background-color: #eee;
            margin-top: 80rem;
            padding: 33rem;
            margin-bottom: 100rem;

            p {
                font-size: 20rem;
                color: #B0B0B0;
            }
        }

        // 放大图片蒙层
        .imgMask {
            width: 100%;
            height: 100%;
            background-color: #0000007d;
            position: absolute;
            top: 0;

            &::v-deep .image_resized {
                width: 750rpx !important;
                height: 300rpx;
            }

        }

    }

    // 弹窗
    .popup {
        width: 100%;
        height: 100%;
        background-color: #0000006d;
        position: fixed;
        left: 0;
        z-index: 999;

        .popupcase {
            width: 100%;
            height: 1045rem;
            background-color: #fff;
            position: fixed;
            bottom: 0;
            border-radius: 20rem 20rem 0 0;

            // 标题
            .popuptitle {
                width: 100%;
                height: 125rem;
                border-bottom: 1rem solid #0000002d;
                font-size: 40rem;
            }

            // 下方标签
            .popuplabel {
                width: 100%;
                height: 300rem;
                padding: 20rem;

                .labellist {
                    height: 80rem;
                    font-size: 30rem;
                    border: 1rem solid #0000004d;
                    padding: 20rem;
                    margin-left: 20rem;
                    margin-top: 40rem;
                }

                .labellist:hover {
                    background-color: #FA784F;
                    color: #fff;
                    border: none;
                }

                .labellistspace {
                    width: 190rem;
                    height: 80rem;
                }
            }

            .buttoncase {
                width: 100%;
                height: 180rem;
                // background-color: #225AE8;
                margin-top: 300rem;

                .cancel {
                    width: 250rem;
                    height: 96rem;
                    border: #FA784F 1rem solid;
                    border-radius: 100rem;
                    font-size: 35rem;
                    color: #FA784F;

                }

                .determine {
                    width: 250rem;
                    height: 96rem;
                    background-color: #FA784F;
                    color: #fff;
                    border-radius: 100rem;
                    font-size: 35rem;
                }
            }
        }

    }
}
</style>
