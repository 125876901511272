<template>
    <div class="wain sc" id="show">
        <!-- 头部 -->
        <header class="hcc">
            <img src="@/assets/img/leftarrow.png" alt="" class="leftarrow" @click="backArrow">
            <p>修改默认密码</p>
        </header>
        <!-- 主体 -->
        <section class="sc">
            <!-- 密码框 -->
            <div class="posscase sc">
                <input type="password" class="setpossword" placeholder="输入登录密码" v-model="password">
                <input type="password" placeholder="请确认输入密码(不少于6位)" v-model="passwordagain">

            </div>
            <!-- 确认按钮 -->
            <button @click="confirm">确认</button>
        </section>
    </div>

</template>

<script>
import watermark from '@/watermark/watermark';

export default {
    name: 'set_possword',

    components: {

    },
    data() {
        return {
            password: "",
            passwordagain: ""
        }
    },
    mounted() {
        this.wxfx();
         //判断有没有水印
         if (localStorage.getItem("shuiyin_status") == 1) {
            watermark.set(
                localStorage.getItem("logo")
            )
        }else {
            watermark.out(
                localStorage.getItem("logo")
            )
        }
    },

    methods: {    
        // 点击返回上一级
        backArrow() {
            this.$router.go(-1)
        },

        // 确定按钮调用接口
        confirm() {
            // 如果2次输入一致
            if (this.password == this.passwordagain) {
                this.$axios({
                    url: "http://y163.njhxzx.com/data/user/updatePassword",
                    methods: "POST",
                    params: {
                        token: localStorage.getItem("token"),
                        password: this.password,
                    }
                }).then(res => {
                    this.$toast.fail(res.data.info)
                    if (res.data.code == 1) {
                        this.$toast.success('登录成功')
                        setTimeout(() => {
                            this.$router.push({
                                path: "/login"
                            })
                        }, 2000);

                    }
                }).catch(err => {

                })
            } else {

            }

        }
    },
}
</script>



<style lang="scss" scoped>
#show {
    position: relative;
    z-index: 999;
    overflow: hidden;
}

.wain {
    width: 100%;
    height: 100%;
    background-color: #F0F3F8;

    // 头部
    header {
        width: 750rem;
        height: 110rem;
        background-color: #FA784F;
        position: relative;

        img {
            width: 21rem;
            height: 38rem;
            margin-left: 20rem;
        }

        p {
            font-size: 38rem;
            color: #fff;
        }

        .leftarrow {
            position: absolute;
            left: 20rem;

        }
    }

    // 主体
    section {
        width: 100%;
        height: 100vh;

        .posscase {
            width: 100%;
            height: 220rem;
            background-color: #fff;
            margin-top: 40rem;

            input {
                width: 95%;
                height: 110rem;
                font-size: 35rem;
                color: #999;

            }

            .setpossword {
                border-bottom: 1rem solid #0000001d;
            }
        }

        // 确认按钮
        button {
            width: 700rem;
            height: 100rem;
            border-radius: 100rem;
            background-color: #FA784F;
            font-size: 40rem;
            color: #fff;
            margin-top: 170rem;
        }
    }
}
</style>
