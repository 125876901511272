<template>
    <div class="wain sc" id="show">
        <!-- 头部-->
        <header class="hcc">
            <img src="@/assets/img/leftarrow.png" alt="" class="leftarrow" @click="backArrow">
            <p>我的收藏</p>
            <button class="establish" @click="openEstablish">
                创建
            </button>
        </header>

        <!-- 主体 -->
        <section class="sc">
            <!-- 标题 -->
            <div class="title hc">
                <p>共创建<span>{{ list.length }}</span>个收藏夹</p>
            </div>
            <!-- 内容列表 -->
            <div class="contentlist hca" v-for="r in list" :key="r.id">
                <!-- 图片 -->
                <div class="contentimg" @click="openDetails(r.id, r.name, r.create_at, list.length, r.image)">
                    <img :src="r.image ? r.image : img" alt="" srcset="" :class="r.image ? imgone : imgtwo">
                </div>
                <!-- 文字 -->
                <div class="contentword">
                    <div class="wordone" @click="openDetails(r.id, r.name, r.create_at, list.length, r.image)">{{ r.name}}</div>
                    <div class="wordtwo" @click="openDetails(r.id, r.name, r.create_at, list.length, r.image)"></div>
                    <div class="wordthree hc" @click="openDetails(r.id, r.name, r.create_at, list.length, r.image)">
                        <p>{{ r.num }} 篇内容</p>
                    </div>
                    <img src="@/assets/img/more.png" alt="" @click="openMore(r.id, r.name)">
                </div>
            </div>

            <!-- 点击更多蒙版 -->
            <div class="mask" v-show="flag">
                <!-- 选择栏 -->
                <div class="choice sc">
                    <div @click="getEdit">编辑</div>
                    <div class="delete" @click="getDelete">删除</div>
                    <div class="cancel" @click="closeMask">取消</div>
                </div>
            </div>


            <!-- 确认框蒙版 -->
            <div class="determinecase hcc" v-show="determine">
                <div class="determine scc">
                    <p>确定要删除吗</p>
                    <div class="buttoncase hca">
                        <button class="cancelbutton" @click="cancelbutton">取消</button>
                        <button class="confirmbutton" @click="confirmbutton">确认</button>
                    </div>
                </div>
            </div>


            <!-- 编辑弹窗 -->
            <div class="editmask scc" v-show="editmask">
                <!-- 编辑框文字 -->
                <div class="editword scc">
                    <div class="editinput hc">
                        <p>修改名称：</p>
                        <input type="text" v-model="editinput">
                    </div>
                    <!-- 按钮盒子 -->
                    <div class="editbuttoncase hcc">
                        <button class="editcancel" @click="rejectedit">取消</button>
                        <button class="editdetermine" @click="sealedit">确定</button>

                    </div>
                </div>
            </div>
        </section>

        <!-- 没有更多了提示 -->
        <div class="nomore hca">
            <img src="@/assets/img/horizontal_line.png" alt="" srcset="">
            <p>没有更多了</p>
            <img src="@/assets/img/horizontal_line.png" alt="" srcset="">
        </div>
    </div>
</template>

<script>
import watermark from '@/watermark/watermark';

export default {
    name: 'my_collection',

    components: {

    },
    data() {
        return {
            flag: false,
            list: "",
            determine: false,
            editmask: false,
            editinput: "",
            img: require('@/assets/img/stars.png'),
            imgone: "imgone",
            imgtwo: "imgtwo",
        }
    },
    mounted() {
        this.wxfx();
        let that = this;
        pushHistory()  // 必须存在
        that.gotoURL(function () {
            pushHistory()  // 必须存在
            that.$router.push({
                path: "/mine"
            })
        })
        // 阻止手机返回键
        if (window.history && window.history.pushState) {
            history.pushState(null, null, document.URL);
            window.addEventListener('popstate', this.fun, false) //false阻止默认事件    this.fun是指返回按建实际要执行的方法
        }
        //判断有没有水印
        if (localStorage.getItem("shuiyin_status") == 1) {
            watermark.set(
                localStorage.getItem("logo")
            )
        } else {
            watermark.out(
                localStorage.getItem("logo")
            )
        }
        // 展示收藏夹列表
        this.$axios({
            url: "http://y163.njhxzx.com/data/Article/getFavorites",
            methods: "post",
            params: {
                token: localStorage.getItem("token"),
            }
        }).then(res => {
            // 如果没有收藏夹调取接口自己创建一个
            if (res.data.data.list.length == 0) {
                this.$axios({
                    url: "http://y163.njhxzx.com/data/Article/addFavorites",
                    methods: "post",
                    params: {
                        token: localStorage.getItem("token"),
                        name: "默认收藏夹",
                    }
                }).then(res => {
                    this.$router.go(0)
                }).catch(err => {

                })
            }

            if (res.data.code == 1) {
                this.list = res.data.data.list
                if (this.list.length == 0) {
                    this.nomore = false
                }
            }

        }).catch(err => {

        })

    },

    methods: {
        // 点击返回上一级
        backArrow() {
            this.$router.push({
                path: "/mine"
            })
        },
        // 取消编辑按钮
        rejectedit() {
            this.editmask = false
        },
        // 确认编辑按钮 调取修改文件夹接口
        sealedit() {
            this.$axios({
                url: "http://y163.njhxzx.com/data/Article/updateFavorites",
                methods: "post",
                params: {
                    token: localStorage.getItem("token"),
                    id: localStorage.getItem("collectionid"),
                    name: this.editinput
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.$router.go(0)
                }


            }).catch(err => {

            })

        },

        // 取消删除收藏夹按钮
        cancelbutton() {
            this.determine = false
        },

        // 删除收藏夹确认按钮
        confirmbutton() {
            this.$axios({
                url: "http://y163.njhxzx.com/data/Article/delFavorites",
                methods: "post",
                params: {
                    token: localStorage.getItem("token"),
                    id: localStorage.getItem("collectionid")
                }
            }).then(res => {
                if (res.data.code == 1) {
                    this.$router.go(0)
                }


            }).catch(err => {

            })
        },

        // 点击跳转到收藏夹详情
        openDetails(a, b, c, d, e) {
            this.$router.push({
                path: "/favorite_details",
                query: {
                    favoritesid: a,
                    name: b,
                    time: c,
                    number: d,
                    img: e

                }
            })
        },

        // 点击跳转到我的收藏夹
        openEstablish() {
            this.$router.push({
                path: "/create_avorites"
            })
        },

        //点击打开编辑蒙版 
        openMore(e, d) {
            this.flag = true
            localStorage.setItem("collectionid", e)
            localStorage.setItem("collectionname", d)
        },

        // 点击关闭蒙版
        closeMask() {
            this.flag = false
        },

        // 点击删除显示提示框
        getDelete() {
            this.determine = true
            this.flag = false
        },

        // 点击编辑按钮
        getEdit() {
            this.flag = false
            this.editmask = true
            this.editinput = localStorage.getItem("collectionname")
        }


    },
}
</script>

<style lang="scss" scoped>
#show {
    position: relative;
    z-index: 999;
    overflow: hidden;
}

// 外边大盒子
.wain {
    width: 100%;
    height: 100%;

    // 头部
    header {
        width: 750rem;
        height: 110rem;
        background-color: #FA784F;
        position: relative;

        img {
            width: 21rem;
            height: 38rem;
            margin-left: 20rem;
        }

        p {
            font-size: 38rem;
            color: #fff;
        }

        .leftarrow {
            position: absolute;
            left: 20rem;

        }

        .establish {
            width: 60rem;
            height: 40rem;
            font-size: 27rem;
            font-weight: 200;
            position: absolute;
            right: 55rem;
            background-color: #FA784F;
            color: #fff;
        }
    }

    // 主体
    section {
        width: 100%;
        // height: 1000rem;

        // 标题
        .title {
            width: 100%;
            height: 85rem;

            p {
                margin-left: 45rem;
                font-size: 27rem;
                color: #999999;

            }

            span {
                font-size: 27rem;
                color: #999999;
            }
        }

        // 内容
        .contentlist {
            width: 662rem;
            height: 233rem;
            position: relative;
            margin-top: 20rem;

            // 图片
            .contentimg {
                width: 224rem;
                height: 224rem;
                background-color: #FFF1ED;
                border-radius: 10rem;
                display: flex;
                justify-content: center;
                align-items: center;


                .imgone {
                    width: 100%;
                    height: 100%;
                }

                .imgtwo {
                    width: 126rem;
                    height: 120rem;
                }
            }

            // 文字
            .contentword {
                width: 415rem;
                height: 100%;
                padding: 10rem;
                position: relative;


                .wordone {
                    font-size: 27rem;
                    font-weight: 600;
                    margin-top: 5rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .wordtwo {
                    font-size: 23rem;
                    margin-top: 25rem;
                    color: #666666;
                }

                .wordthree {
                    font-size: 19rem;
                    margin-top: 125rem;
                    color: #A0A0A0;
                }

                img {
                    width: 8rem;
                    height: 33rem;
                    position: absolute;
                    right: 0rem;
                    top: 12rem;
                }


            }


        }

        // 蒙版
        .mask {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            background-color: #0000002d;
            z-index: 999;


            // 选择栏
            .choice {
                width: 100%;
                height: 413rem;
                background-color: #fff;
                position: absolute;
                bottom: 0;

                div {
                    width: 100%;
                    height: 141rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 33rem;
                }

                .delete {
                    color: #FF784F;
                }

                .cancel {
                    color: #BBBBBB;
                }
            }
        }

        // 确认框
        .determinecase {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            background-color: #0000002d;
            z-index: 999;

            .determine {
                width: 500rem;
                height: 300rem;
                background-color: #fff;
                border-radius: 50rem;

                p {
                    font-size: 35rem;
                }

                .buttoncase {
                    width: 70%;
                    height: 75rem;
                    margin-top: 30rem;

                    .cancelbutton {
                        width: 100rem;
                        height: 60rem;
                        font-size: 25rem;
                        border-radius: 300rem;
                    }

                    .confirmbutton {
                        width: 100rem;
                        height: 60rem;
                        font-size: 25rem;
                        border-radius: 300rem;
                        color: #fff;
                        background: #FF784F;
                    }
                }
            }

        }

        // 编辑蒙版
        .editmask {
            width: 100%;
            height: 100%;
            position: fixed;
            background-color: #0000002d;
            top: 0;

            .editword {
                width: 500rem;
                height: 300rem;
                background-color: #fff;
                border-radius: 30rem;

                .editinput {
                    margin-top: 70rem;

                    p {
                        font-size: 25rem;
                    }

                    input {
                        width: 264rem;
                        height: 50rem;
                        background-color: #f5f5f5;
                        font-size: 25rem;
                        padding-left: 10rem;
                    }
                }


            }

            // 按钮盒子
            .editbuttoncase {
                width: 70%;
                height: 100rem;
                // background-color: #FA784F;
                margin-top: 11rem;

                .editcancel {
                    width: 118rem;
                    height: 64rem;
                    background-color: #fff;
                    font-size: 25rem;
                    border-radius: 50rem;
                }

                .editdetermine {
                    width: 118rem;
                    height: 64rem;
                    background-color: #fff;
                    font-size: 25rem;
                    border-radius: 50rem;
                    color: #FF784F;
                }
            }
        }



    }

    // 没有更多了提示
    .nomore {
        width: 465rem;
        height: 40rem;
        margin-top: 64rem;
        margin-bottom: 50rem;

        p {
            font-size: 26rem;
            color: #C9C9C9;
        }
    }

}
</style>
