<template>
  <div class="wain" id="show">
    <!-- 头部 -->
    <header>
      <!-- 标题 -->
      <p>我的</p>
      <!-- 登录栏 -->
      <div class="logincase hc">
        <!-- 头像栏 -->
        <div class="headportrait hc">
          <img src="@/assets/img/user_icon.png" alt="" srcset="" v-show="imgflagone" />
          <img :src="img" alt="" srcset="" v-show="imgflagtwo" />
          <p v-show="flaglogin">{{ username }}</p>
        </div>
      </div>
      <!-- 收藏 浏览栏 -->
      <div class="favorite hc" v-show="flag">
        <!-- 列表 -->
        <div class="favoritelistone sca" @click="openLike">
          <p>{{ collectionnumber }}</p>
          <p class="word">收藏</p>

        </div>
        <div class="favoritelisttwo sca" @click="openBrowse">
          <p>{{ browsenumber }}</p>
          <p class="word">浏览</p>
        </div>
      </div>
    </header>

    <!-- 主体 -->
    <section class="sc">
      <!-- 列表 -->
      <div class="sectionlist hc" @click="openLike">
        <!-- 我的收藏 -->
        <img src="@/assets/img/like.png" alt="" class="likeimg" />
        <div>
          <p>我的收藏</p>
        </div>

        <img src="@/assets/img/arrow.png" alt="" class="likearrow" />
      </div>

      <div class="sectionlist hc" @click="openBrowse">
        <!-- 浏览记录 -->
        <img src="@/assets/img/select.png" alt="" class="browseimg" />
        <div>
          <p>浏览记录</p>
        </div>

        <img src="@/assets/img/arrow.png" alt="" class="browsearrow" />
      </div>

      <div class="sectionlist hc" @click="openChangePassword">
        <!-- 修改密码 -->
        <img src="@/assets/img/change_password.png" alt="" class="browseimg" />
        <div>
          <p>修改密码</p>
        </div>

        <img src="@/assets/img/arrow.png" alt="" class="browsearrow" />
      </div>


      <div class="sectionlist hc" @click="openLogOut">
        <!-- 退出登录 -->
        <img src="@/assets/img/log_out.png" alt="" class="browseimg" />
        <div>
          <p>退出登录</p>
        </div>

        <img src="@/assets/img/arrow.png" alt="" class="browsearrow" />
      </div>
      <!-- 确认框蒙版 -->
      <div class="determinecase hcc" v-show="determine">
        <div class="determine scc">
          <p>确定要退出吗</p>
          <div class="buttoncase hca">
            <button class="cancelbutton" @click="cancelbutton">取消</button>
            <button class="confirmbutton" @click="confirmbutton">确认</button>
          </div>
        </div>
      </div>
    </section>
    <!-- 底部栏 -->
    <my-footer></my-footer>
  </div>
</template>

<script>
//引用文件
import myFooter from "@/components/myFooter.vue";
import indexapi from "@/api/index"
import watermark from '@/watermark/watermark';


export default {
  name: "mine",
  
  components: {
    myFooter,
    indexapi,

  },
  data() {
    return {
      flagnologin: false,
      flaglogin: false,
      flagopenid: false,
      flag: false,
      openid: "",
      username: "",
      img: "",
      collectionnumber: "",
      browsenumber: "",
      imgflagone: false,
      imgflagtwo: false,
      determine: false
    }
  },
  mounted() {
    this.wxfx();
    
    //判断有没有水印
    if (localStorage.getItem("shuiyin_status") == 1) {
      watermark.set(
        localStorage.getItem("logo")
      )
    } else {
      watermark.out(
        localStorage.getItem("logo")
      )
    }
    //  判断有没有登录
    if (localStorage.getItem("token")) {
      this.flaglogin = true
      this.flag = true
    }
    // 获取用户信息
    this.$axios({
      url: "http://y163.njhxzx.com/data/user/userinfo",
      methods: "POST",
      params: {
        token: localStorage.getItem("token")
      }
    }).then(res => {
      // 如果登录成功 将信息渲染到页面
      this.username = res.data.data.nickname
      this.img = res.data.data.headimg
      this.collectionnumber = res.data.data.favoritesNewsNum
      this.browsenumber = res.data.data.collectNewsNum
      if (this.img) {
        this.imgflagtwo = true
      } else {
        this.imgflagone = true
      }


    }).catch(err => {

    })
  },

  methods: {
    // 打开我的收藏
    openLike() {
      this.$router.push({
        path: "/my_collection"
      })
    },
    // 打开浏览记录
    openBrowse() {
      this.$router.push({
        path: "/browse_records"
      })
    },
    // 取消按钮
    cancelbutton() {
      this.determine = false
    },
    // 确认按钮
    confirmbutton() {
      this.$axios({
        url: "http://y163.njhxzx.com/data/user/loginout",
        methods: "POST",
        params: {
          token: localStorage.getItem("token")
        }
      }).then(res => {
        // 如果登录成功 
        if (res.data.code == 1) {
          this.$toast.success('退出成功')
          this.determine = false
          setTimeout(() => {
            this.$router.push({
              path: "/login"
            })
          }, 2000)

          localStorage.removeItem("token")
        }

      }).catch(err => {

      })
    },
    // 点击跳转修改密码
    openChangePassword() {
      this.$router.push({
        path: "/modify_possword"
      })
    },
    // 点击退出登录
    openLogOut() {
      this.determine = true

    }
  },
};
</script>



<style lang="scss" scoped>
#show {
  position: relative;
  z-index: 999;
  overflow: hidden;
  height: 100vh;
}

.wain {
  width: 100%;
  height: 100%;

  // 头部
  header {
    width: 100%;
    height: 495rem;
    background-color: #ff784f;
    padding: 40rem;

    p {
      font-size: 50rem;
      color: #fff;
    }

    // 登录栏
    .logincase {
      width: 100%;
      height: 125rem;

      // 头像栏
      .headportrait {
        width: 100%;
        height: 140rem;
        margin-top: 100rem;

        img {
          width: 150rem;
          height: 150rem;
          border-radius: 100rem;
        }

        p {
          width: 300rem;
          margin-left: 50rem;
          font-size: 34rem;
        }
      }
    }

    .favorite {
      width: 630rem;
      height: 80rem;
      margin-top: 90rem;

      .favoritelistone {
        width: 94rem;
        height: 80rem;
        margin-left: 20rem;

        p {
          font-size: 32rem;
        }

        .word {
          font-size: 25rem;
        }
      }

      .favoritelisttwo {
        width: 94rem;
        height: 80rem;
        margin-left: 75rem;

        p {
          font-size: 32rem;
        }

        .word {
          font-size: 25rem;
        }
      }
    }
  }

  // 主体
  section {
    width: 100%;
    height: 752rem;
    background-color: #fff;
    border-radius: 50rem 50rem 0 0;
    position: fixed;
    top: 450rem;

    // 确认框
    .determinecase {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      background-color: #0000002d;
      z-index: 999;

      .determine {
        width: 500rem;
        height: 300rem;
        background-color: #fff;
        border-radius: 50rem;

        p {
          font-size: 35rem;
        }

        .buttoncase {
          width: 70%;
          height: 75rem;
          margin-top: 30rem;

          .cancelbutton {
            width: 100rem;
            height: 60rem;
            font-size: 25rem;
            border-radius: 300rem;
          }

          .confirmbutton {
            width: 100rem;
            height: 60rem;
            font-size: 25rem;
            border-radius: 300rem;
            color: #fff;
            background: #FF784F;
          }
        }
      }

    }

    // 主体列表
    .sectionlist {
      width: 650rem;
      height: 130rem;

      // 我的收藏
      .likeimg {
        width: 7%;
        height: 31%;
      }

      div {
        width: 300rem;
        height: 50rem;

        p {
          width: 250rem;
          height: 50rem;
          font-size: 30rem;
          margin-left: 58rem;
          white-space: normal;
          overflow: hidden;
          line-height: 50rem;


        }
      }

      .likearrow {
        width: 14rem;
        height: 23rem;
        margin-left: 293rem;
      }

      // 浏览记录
      .browseimg {
        width: 6%;
        height: 31%;
      }



      .browsearrow {
        width: 14rem;
        height: 23rem;
        margin-left: 293rem;
      }
    }
  }
}
</style>