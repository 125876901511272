<template>
    <div class="wain sc">
        <header class="hc">
            <!-- 返回 -->
            <img src="@/assets/img/login_back.png" alt="" srcset="" class="back" @click="backArrow">
        </header>

        <!-- 主体 -->
        <section class="sc">
            <!-- 标题 -->
            <div class="title hcc" v-show="titleflag">
                <p>账号密码登录</p>
            </div>
            <!-- 已登录标题 -->
            <div class="logintitle" v-show="loginflag">
                <!-- 头像 -->
                <div class="loginportrait hcc">
                    <img :src="userIcon" alt="">
                </div>

                <!-- 文字 -->
                <div class="loginword">
                    <p>{{ petName }}</p>
                    <span>欢迎登录！</span>
                </div>

            </div>

            <!-- 输入框 -->
            <div class="inputcase sc">
                <div class="phonecase hc">
                    <img src="@/assets/img/login_phone.png" alt="" class="phoneimg">
                    <input type="text" class="user" placeholder="输入手机号" v-model="user" @keyup="userModify">
                </div>
                <div class="posswordcase hc">
                    <img src="@/assets/img/login_possword.png" alt="" class="posswordimg">
                    <input type="password" class="possword" placeholder="输入密码" v-model="password">
                </div>

            </div>
            <div class="loginbutton hcc" @click="login">
                登录
            </div>
            <!-- 底部图片 -->
            <div class="footimg">
                <img src="@/assets/img/login_img.png" alt="">
            </div>
            <!-- 登录成功提示 -->
            <div class="tip hcc" v-show="logintip">
                <p>登录超时，请重新登录</p>
            </div>

        </section>
    </div>

</template>

<script>

export default {
    name: 'login',

    components: {

    },
    data() {
        return {
            titleflag: true,
            loginflag: false,
            user: localStorage.getItem("contact_phone") ? localStorage.getItem("contact_phone") : "",
            password: "",
            logintip: false,
            list: "",
            userIcon: localStorage.getItem("userIcon"),
            contact_phone: "",
            petName: localStorage.getItem("petName"),
            openid: ""
        }
    },
    created() {
        this.wxfx();
        // 如果flag的值等于1 显示登录超时
        if (this.$route.query.flag == 1) {
            this.$toast.success('登录超时，请重新登录')
        }
        // 如果在本地缓存能获取用户信息就显示用户头像
        if (localStorage.getItem("userIcon") || localStorage.getItem("contact_phone") || localStorage.getItem("petName")) {
            this.loginflag = true
            this.titleflag = false
        } else {
            this.loginflag = false
            this.titleflag = true
        }
         // 拿url后面的=和#之间的openid值
        this.star = window.location.href.indexOf("=")
        this.end = window.location.href.indexOf("#")
        this.openid = window.location.href.substr(this.star + 1, this.end - this.star - 1)
    },
   
    methods: {

        
        // 修改用户名时
        userModify() {
            this.loginflag = false
            this.titleflag = true
            if (this.user == localStorage.getItem("contact_phone")) {
                this.loginflag = true
                this.titleflag = false
            }
        },

        // 点击返回上一级
        backArrow() {
            this.$router.back()
        },

        // 点击调取登录接口
        login() {
            this.$axios({
                url: "http://y163.njhxzx.com/data/User/login",
                methods: "post",
                params: {
                    openid: this.openid,
                    phone: this.user,
                    password: this.password
                }
            }).then(res => {
                this.info = res.data.info
                this.$toast.fail(this.info)
                // 如果登录成功 
                if (res.data.data.updatepw == 0) {
                    this.$toast.success('登录成功')
                    // 把token等信息存入缓存 并跳转到首页
                    localStorage.setItem("token", res.data.data.token)
                    localStorage.setItem("logo", res.data.data.shuiyin)
                    localStorage.setItem("shuiyin_status", res.data.data.shuiyin_status)
                    setTimeout(() => {
                        this.$router.push({
                            path: "/index"
                        })
                    }, 2000)
                }

                // 如果值等于1 进入修改初始密码页面
                if (res.data.data.updatepw == 1) {
                    this.$toast.success('登录成功')
                    // 把token存入缓存 并跳转到首页
                    localStorage.setItem("token", res.data.data.token)
                    setTimeout(() => {
                        this.$router.push({
                            path: "/set_possword"
                        })
                    }, 2000)
                }   
                
                // 登录成功获取用户信息
                this.$axios({
                    url: "http://y163.njhxzx.com/data/user/userinfo",
                    methods: "POST",
                    params: {
                        token: localStorage.getItem("token")
                    }
                }).then(res => {
                    // 如果登录成功 
                    this.list = res.data.data
                    this.userIcon = this.list.headimg
                    this.contact_phone = this.list.contact_phone
                    this.petName = this.list.nickname
                    localStorage.setItem("userIcon", this.userIcon)
                    localStorage.setItem("contact_phone", this.contact_phone)
                    localStorage.setItem("petName", this.petName)
                }).catch(err => {

                })

            }).catch(err => {

            })
        }

    },
}
</script>



<style lang="scss" scoped>
.van-toast--middlet {
    width: 700rem;
    height: 700rem;
    font-size: 60rem;
}

.wain {
    width: 100%;
    height: 100vh;

    header {
        width: 100%;
        height: 200rem;

        // 返回
        .back {
            width: 19rem;
            height: 35rem;
            margin-left: 57rem;

        }
    }


    // 主体
    section {
        width: 100%;
        height: 1080rem;
        // background-color: aqua;
        margin-top: 120rem;

        // 标题
        .title {
            width: 100%;
            height: 340rem;
            font-size: 65rem;
            color: #FF784F;
            margin-top: -100rem;
        }

        // 已登录标题
        .logintitle {
            width: 100%;
            height: 430rem;
            // background-color: #FF784F;
            margin-top: -100rem;

            // 头像
            .loginportrait {
                width: 100%;
                height: 200rem;

                img {
                    width: 172rem;
                    height: 172rem;
                }
            }

            // 文字
            .loginword {
                width: 100%;
                height: 130rem;
                // background-color: aqua;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                p {
                    font-size: 43rem;
                    margin-top: 40rem;

                }

                span {
                    font-size: 41rem;
                    color: #FF784F;
                    margin-top: 40rem;
                    margin-left: 18rem;
                }
            }
        }

        // 输入框
        .inputcase {
            width: 600rem;
            height: 220rem;

            .phonecase {
                width: 600rem;
                height: 110rem;
                border-bottom: 1rem solid #0000001d;


                .user {
                    width: 100%;
                    height: 105rem;
                    background-color: #fff;
                    font-size: 30rem;
                    color: #999;
                    padding-left: 50rem;
                }

                .phoneimg {
                    width: 40rem;
                    height: 48rem;
                    margin-left: 10rem;
                }

            }

            .posswordcase {
                width: 600rem;
                height: 110rem;
                border-bottom: 1rem solid #0000001d;

                .possword {
                    width: 100%;
                    height: 105rem;
                    background-color: #fff;
                    font-size: 30rem;
                    color: #999;
                    padding-left: 50rem;
                }

                .posswordimg {
                    width: 40rem;
                    height: 48rem;
                    margin-left: 10rem;

                }
            }
        }

        // 登录
        .loginbutton {
            width: 603rem;
            height: 100rem;
            background-color: #FF784F;
            font-size: 50rem;
            color: #fff;
            border-radius: 100rem;
            margin-top: 70rem;
        }

        .loginbutton:hover {
            background-color: #FFBBA7;
        }

        // 底部图片
        .footimg {
            width: 100%;
            height: 140rem;
            position: fixed;
            bottom: 0;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .tip {
            width: 450rem;
            height: 100rem;
            position: absolute;
            top: 100rem;
            font-size: 34rem;
        }

    }


}
</style>
