<template>
    <!-- 背景图 -->
    <div class="wain scc">
        <!-- 马上登录按钮 -->
        <div class="button">马上登录</div>
        <!-- 提示 -->
        <div class="tip hca" v-show="tipflag">
            <!-- 感叹号 -->
            <div class="word hcc">
                !
            </div>
            <p>当前微信没有登录权限，请联系管理员</p>
        </div>
    </div>

</template>

<script>

export default {
    name: 'login_cover',

    components: {

    },
    data() {
        return {
            tipflag: false,
            successprompt: false,
            openid: "",
            star: "",
            end: ""
        }
    },
    mounted() {
        this.wxfx();
        // 拿url后面的=和#之间的openid值
        this.star = window.location.href.indexOf("=")
        this.end = window.location.href.indexOf("#")
        this.openid = window.location.href.substr(this.star + 1, this.end - this.star - 1)
        // 如果有token直接登录
        if (localStorage.getItem("token")) {
            this.$router.push({
                path: "/index"
            })
        } else {
            this.$axios({
                url: "http://y163.njhxzx.com/data/user/checkOpenid",
                methods: "post",
                params: {
                    openid: this.openid
                }
            }).then(res => {
                // 如果合法 跳转设置密码页面
                if (res.data.code == 1) {
                    this.$toast.success('授权成功')
                    setTimeout(() => {
                        this.$router.push({
                            path: "/login"
                        })
                    }, 3000)

                } else {
                    // 失败显示重新授权提示
                    this.tipflag = true

                }

            }).catch(err => {

            })

        }
    },
}
</script>



<style lang="scss" scoped>
.wain {
    width: 100%;
    height: 100vh;
    background-image: url(@/assets/img/login_bg.png);
    background-size: 100% 100%;
    background-position: center center;

    // 马上登录按钮
    .button {
        width: 340rem;
        height: 100rem;
        border: 3rem solid #fff;
        font-size: 46rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10rem;
        color: #fff;
        position: fixed;
        bottom: 322rem;
        left: 228rem;

    }

    .tip {
        width: 80%;
        height: 80rem;
        background-color: #000;
        border-radius: 15rem;

        .word {
            width: 40rem;
            height: 40rem;
            background-color: #fff;
            border-radius: 100rem;
            font-size: 36rem;
        }

        p {
            font-size: 30rem;
            color: #fff;
        }
    }

    .successprompt {
        width: 400rem;
        height: 200rem;
        background-color: #fff;
        font-size: 30rem;
        border-radius: 30rem;
    }

}
</style>
