<template>
    <div class="wain sc" id="show">
        <!-- 头部-->
        <header class="hcc">
            <img src="@/assets/img/leftarrow.png" alt="" class="leftarrow" @click="backArrow">
            <p>浏览记录</p>
        </header>
        <!-- 主体 -->
        <section class="sc">
            <!-- 主体列表 -->
            <div :class="r.record.length == 0 ? hide : sectionlist" v-for="r in recordslist" @click="getSectionlist(r.code) "
                v-show="sectionlistWord">
                <img :src="r.record.cover" alt="" :class="r.record.cover ? imageFlagOne : imageFlagTwo">
                <!-- 文字内容 -->
                <div :class="r.record.cover ? wordOne : wordTwo">
                    <p class="wordone">{{ r.record.name }}</p>
                    <div class="wordcase">
                        <img src="@/assets/img/eyes.png" alt="" srcset="">
                        <p class="wordtwo">{{ r.record.num_read }}</p>
                        <p class="wordthree">{{ r.user.nickname }}</p>
                        <p class="wordfour">{{ create_at }}</p>
                    </div>
                </div>
            </div>
            <p v-show="word">没有浏览记录</p>
            <!-- 没有更多了提示 -->
            <div class="nomore hca" v-show="nomore">
                <img src="@/assets/img/horizontal_line.png" alt="" srcset="">
                <p class="nomoreWord">没有更多了</p>
                <img src="@/assets/img/horizontal_line.png" alt="" srcset="">
            </div>
        </section>
    </div>

</template>

<script>
import watermark from '@/watermark/watermark';

export default {
    name: 'browse_records',

    components: {

    },
    data() {
        return {
            flag: false,
            loading: false,
            recordslist: "",
            create_at: "",
            sectionlistWord: true,
            word: false,
            wordList: "",
            imageList: [],
            imageFlagOne: "imageFlagOne",
            imageFlagTwo: "imageFlagTwo",
            wordOne: "contentword",
            wordTwo: "contentwordTwo",
            nomore: true,
            hide: "hide",
            sectionlist: "sectionlist"

        }
    },
   
    mounted() {
        this.wxfx();
    
        //判断有没有水印
        if (localStorage.getItem("shuiyin_status") == 1) {
            watermark.set(
                localStorage.getItem("logo")
            )
        } else {
            watermark.out(
                localStorage.getItem("logo")
            )
        }
        // 调取浏览记录接口
        this.$axios({
            url: "http://y163.njhxzx.com/data/article/getHistory",
            methods: "get",
            params: {
                token: localStorage.getItem("token")
            }
        }).then(res => {
            if (res.data.code == 1) {
                this.recordslist = res.data.data.list
                // 如果有封面图
                for (var i in this.recordslist) {
                    if (this.recordslist[i].record.cover == "") {
                        this.imageList = this.recordslist[i]
                    }
                }
                // 判断有没有浏览记录
                if (this.recordslist.length == 0) {
                    this.sectionlistWord = false,
                        this.word = true
                    this.nomore = false
                } else {
                    this.sectionlistWord = true,
                        this.word = false
                    this.nomore = true
                }

                for (var i in this.recordslist) {
                    this.create_at = this.recordslist[i].record.create_at.slice(0, 11)
                }
            }

        }).catch(err => {

        })


    },

    methods: {
        // 点击返回上一级
        backArrow() {
            this.$router.push({
                path:"/mine"
            })
        },
        // 点击跳转到对应文章界面
        getSectionlist(e) {
            localStorage.setItem("path", "/browse_records")
            this.$router.push({
                path: "/click_details",
                query: {
                    codeid: e

                }
            })
        }

    },
}
</script>



<style lang="scss" scoped>
#show {
    position: relative;
    z-index: 999;
    overflow: hidden;

}

.wain {
    width: 100%;
    height: 100%;




    // 头部
    header {
        width: 750rem;
        height: 110rem;
        background-color: #FA784F;
        position: relative;

        img {
            width: 21rem;
            height: 38rem;
            margin-left: 20rem;
        }

        p {
            font-size: 38rem;
            color: #fff;
        }

        .leftarrow {
            position: absolute;
            left: 20rem;

        }
    }

    // 主体
    section {
        width: 100%;

        p {
            font-size: 30rem;
            color: #999;
        }
        // 隐藏class名
        .hide {
            display: none;
        }

        // 主体列表
        .sectionlist {
            width: 620rem;
            height: 230rem;
            margin-top: 55rem;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .imageFlagOne {
                width: 210rem;
                height: 210rem;
            }

            .imageFlagTwo {
                display: none;
            }

            // 文字内容 第一种形态
            .contentword {
                width: 360rem;
                height: 100%;

                .wordone {
                    font-size: 30rem;
                    margin-top: 10rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                }

                .wordcase {
                    width: 88%;
                    height: 24rem;
                    margin-top: 120rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;

                    img {
                        width: 32rem;
                        height: 22rem;
                    }

                    .wordtwo {
                        font-size: 21rem;
                        color: #999;
                    }

                    .wordthree {
                        font-size: 21rem;
                        color: #999;
                    }

                    .wordfour {
                        font-size: 21rem;
                        color: #999;
                    }
                }
            }
        }

        // 文字内容 第二种形态
        .contentwordTwo {
            width: 360rem;
            height: 100%;
            margin-left: -190rem;

            .wordone {
                font-size: 30rem;
                margin-top: 10rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .wordcase {
                width: 88%;
                height: 24rem;
                margin-top: 100rem;
                display: flex;
                align-items: center;
                justify-content: space-around;

                img {
                    width: 32rem;
                    height: 22rem;
                }

                .wordtwo {
                    font-size: 21rem;
                    color: #999;
                }

                .wordthree {
                    font-size: 21rem;
                    color: #999;
                }

                .wordfour {
                    font-size: 21rem;
                    color: #999;
                }
            }
        }


        // 数据加载中
        .load {
            width: 100%;
            height: 30rem;
            margin-top: 85rem;

            img {
                width: 26rem;
                height: 26rem;
            }

            p {
                color: #999;
                margin-left: 15rem;
            }
        }

        // 蒙版
        .mask {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            background-color: #0000002d;


            // 选择栏
            .choice {
                width: 100%;
                height: 413rem;
                background-color: #fff;
                position: absolute;
                bottom: 0;

                div {
                    width: 100%;
                    height: 141rem;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 33rem;
                }

                .delete {
                    color: #FF784F;
                }

                .cancel {
                    color: #BBBBBB;
                }
            }
        }

        // 没有更多了提示
        .nomore {
            width: 465rem;
            height: 40rem;
            margin-top: 64rem;
            margin-bottom: 50rem;

            .nomoreWord {
                font-size: 26rem;
                color: #C9C9C9;
                margin-top: 0rem;
            }
        }


    }
}
</style>
